import React, { useEffect, useRef, useState, Fragment, useMemo } from "react";
import BG from "./assets/images/bg.jpg";
import asd from "./assets/images/moon.png";
import MobileMoonBG from "./assets/images/mobile_moon_bg.jpg";
import MobileBG from "./assets/images/mobile_bg.jpg";
import YouTube, { Options } from "react-youtube";
import CrescentLogoSVG from "./assets/images/crescent_logo.svg";
import CrescentLogomarkSVG from "./assets/images/crescent_logomark.svg";
import CrescentLSVG from "./assets/images/crescent.svg";
import MoonPNG from "./assets/images/moon.png";
import Fullmoon from "./assets/images/fullmoon.png";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { SiDiscord } from "react-icons/si";
import { SiGitbook, SiGithub, SiYoutube } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { BsMedium, BsTwitter } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { ABOUT_MENU, COMMUNITY_MENU } from "./constants/menu";
import { isMobile } from "react-device-detect";
import { Routes, Route, Link } from "react-router-dom";
import Main from "./pages/Main";
import Newsletter from "./pages/Newsletter";

function App() {
  useEffect(() => {
    setViewHeight();
    window.addEventListener("resize", setViewHeight);
  }, []);

  const setViewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="newsletter" element={<Newsletter />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
