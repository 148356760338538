import { useState, useRef, useEffect } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { SiDiscord } from "react-icons/si";
import { SiGitbook, SiGithub, SiYoutube } from "react-icons/si";
import { BsMedium, BsTwitter } from "react-icons/bs";
import { FaTelegramPlane, FaRegCheckCircle } from "react-icons/fa";
import { IoIosCalculator } from "react-icons/io";
import bg2 from "../assets/images/bg2.jpg";
import smbg2 from "../assets/images/sm_bg2.jpg";

function App() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let interval = setInterval(() => {
      let successElement: HTMLElement | null =
        document.querySelector(".row-success");
      if (successElement && successElement?.style?.["display"] !== "none") {
        setIsSuccess(true);
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    setViewHeight();
    window.addEventListener("resize", setViewHeight);
  }, []);

  const setViewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const _onChange = (event: any) => {
    setEmail(event.target.value);
    let input = document.querySelector(
      ".ml-form-formContent input"
    ) as HTMLInputElement;
    input.value = event.target.value;
  };

  const _submit = (event: any) => {
    event.preventDefault();
    if (!email) {
      return;
    }
    setIsLoading(true);
    let button = document.querySelector(
      ".ml-form-formContent button"
    ) as HTMLButtonElement;
    button.click();

    const interval = setInterval(() => {
      let successElement: HTMLElement | null =
        document.querySelector(".row-success");
      if (successElement && successElement?.style?.["display"] !== "none") {
        setIsSuccess(true);
        clearInterval(interval);
      }
      let loadingElement: HTMLElement | null = document.querySelector(
        ".ml-block-form .loading"
      );
      if (loadingElement && loadingElement?.style?.["display"] === "none") {
        setIsLoading(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const onFocus = (event: any) => {
    setIsFocus(true);
    setViewHeight();
  };

  const onBlur = (event: any) => {
    setIsFocus(false);
    setViewHeight();
  };

  return (
    <div className="section h-screen relative px-3">
      <img
        src={bg2}
        alt=""
        className="absolute w-full h-screen top-0 left-0 -z-10 object-cover"
      />
      <img
        src={smbg2}
        alt=""
        className="md:hidden absolute w-full h-screen top-0 left-0 -z-10 object-cover"
      />
      <div className="h-full flex flex-col">
        <div className="grow flex flex-col md:justify-center items-center">
          <h1
            className="text-center mt-[5rem] font-aachenn text-[1.875rem] md:text-[4.5rem] leading-none text-white -tracking-[0.03em]"
            style={{ textShadow: "0px 0px 8px #FFD298" }}
          >
            Illuminate Cosmos <br />
            Together with Crescent
          </h1>
          <div
            className="ml-form-embed disply"
            data-account="2119446:z2g1i8j0p3"
            data-form="5537690:d8r9h3"
            style={{ position: "absolute", opacity: 0, zIndex: -10 }}
          />
          <div className="mt-4 md:mt-8 font-medium text-lg tracking-[0.01em] opacity-80 text-white text-center">
            Sign up for the Crescent Newsletter and check your CRE airdrop!
          </div>
          <form
            className="mt-[3rem] md:flex w-full md:w-fit"
            onSubmit={_submit}
          >
            <div className="relative">
              {isSuccess && (
                <div className="absolute w-full h-full right-4 flex justify-end items-center">
                  <FaRegCheckCircle className="w-[1.5rem] h-[1.5rem] text-[#30B50A] top-4 align-middle" />
                </div>
              )}
              <input
                ref={inputRef}
                value={email}
                onChange={_onChange}
                // disabled={}
                onFocus={onFocus}
                onBlur={onBlur}
                type="email"
                inputMode="email"
                className="w-full md:w-[29rem] py-4 px-6 text-lg font-semibold rounded-[0.625rem] shadow-glow-thin-d"
                placeholder="Enter your email address"
              />
            </div>

            <div className="mt-5 md:mt-0 md:ml-3 rounded-[0.625rem] shadow-glow-normal hover:shadow-glow-hover transition-all">
              <button
                type="submit"
                className="w-full md:w-fit bg-gradient-to-b from-[#FFCB89] to-[#FFA939] text-[#333333] font-bold py-4 px-6 text-lg rounded-[0.625rem] grow shadow-glow h-full"
                disabled={isLoading || isSuccess}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center ">
                    <div className="w-5 h-5 border-b-[3px] rounded-full border-grayCRE-100-l animate-spin"></div>
                  </div>
                ) : (
                  "Sign up for Newsletter"
                )}
              </button>
            </div>
          </form>
          {isSuccess && (
            <div className="text-center mt-8 opacity-80 text-white">
              <div className="font-bold text-2xl leading-none tracking-[0.01em]">
                Thank you for signing up!
              </div>
              <div className="mt-1 font-medium text-lg tracking-[0.01em]">
                A confirmation mail has been sent to your entered address.
              </div>
            </div>
          )}
          <div className="mt-9">
            <div className="flex text-white items-start">
              <a
                className="mr-[2rem] md:mr-[3.125rem] hover:opacity-50 transition-opacity"
                href="https://crescentnetwork.medium.com/"
                target="_blank"
              >
                <BsMedium className="w-[2.5rem] h-[2.5rem] md:w-[4rem] md:h-[4rem] p-1" />
              </a>
              <a
                className="mr-[2rem] md:mr-[3.125rem] hover:opacity-50 transition-opacity"
                href="https://t.me/crescentnetwork"
                target="_blank"
              >
                <FaTelegramPlane className="w-[2.5rem] h-[2.5rem] md:w-[4rem] md:h-[4rem] p-2" />
              </a>
              <a
                className="mr-[2rem] md:mr-[3.125rem] hover:opacity-50 transition-opacity"
                href="https://twitter.com/CrescentHub"
                target="_blank"
              >
                <BsTwitter className="w-[2.5rem] h-[2.5rem] md:w-[4rem] md:h-[4rem] p-2" />
              </a>
              <a
                className="mr-[2rem] md:mr-[3.125rem] hover:opacity-50 transition-opacity"
                href="https://discord.com/invite/vmjfqHy4UA"
                target="_blank"
              >
                <SiDiscord className="w-[2.5rem] h-[2.5rem] md:w-[4rem] md:h-[4rem] p-2" />
              </a>
              <a
                className="flex flex-col justify-center items-center hover:opacity-50 transition-opacity"
                href="https://airdrop.crescent.network"
                target="_blank"
              >
                <IoIosCalculator className="w-[2.5rem] h-[2.5rem] md:w-[4rem] md:h-[4rem] p-2" />
                <div className="text-[0.625rem] leading-tight text-center">
                  Airdrop
                  <br />
                  Calculator
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="font-aachenn text-white opacity-80 md:text-[2rem] text-xl mb-[2rem] md:mb-[4rem] md:mr-[3.5rem] text-center md:text-right">
          From the creators of Gravity DEX
        </div>
      </div>
    </div>
  );
}

export default App;
