import React, { useEffect, useRef, useState, Fragment, useMemo } from "react";
import BG from "../assets/images/bg.jpg";
import asd from "../assets/images/moon.png";
import MobileMoonBG from "../assets/images/mobile_moon_bg.jpg";
import MobileBG from "../assets/images/mobile_bg.jpg";
import YouTube, { Options } from "react-youtube";
import CrescentLogoSVG from "../assets/images/crescent_logo.svg";
import CrescentLogomarkSVG from "../assets/images/crescent_logomark.svg";
import CrescentLSVG from "../assets/images/crescent.svg";
import MoonPNG from "../assets/images/moon.png";
import Fullmoon from "../assets/images/fullmoon.png";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { SiDiscord } from "react-icons/si";
import { SiGitbook, SiGithub, SiYoutube } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { BsMedium, BsTwitter } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { ABOUT_MENU, COMMUNITY_MENU } from "../constants/menu";
import { isMobile } from "react-device-detect";

const opts: Options = {
  height: "100%",
  width: "100%",
  playerVars: {
    showinfo: 1,
    controls: 1,
    listType: "user_uploads",
    rel: 0,
  },
};

const ICON: any = {
  Blog: BsMedium,
  Docs: SiGitbook,
  Github: SiGithub,
  Discord: SiDiscord,
  Telegram: FaTelegramPlane,
  Twitter: BsTwitter,
  Youtube: SiYoutube,
};

const DropdownMenu = ({ menu }: any) => {
  return (
    <div className="group inline-block">
      <div className="sBOLD16 py-3 text-white opacity-70 hover:opacity-100 hover:TEXT-SHADOW-GLOW-1-d">
        <div className="flex items-center cursor-pointer">
          <span className="mr-1">{menu.name}</span>
          <FiChevronDown />
        </div>
      </div>
      <div className="pointer-events-none group-hover:pointer-events-auto flex opacity-0 group-hover:opacity-100 absolute left-0 flex-col py-5 px-5 bg-dark2 rounded-[0.625rem] MEDIUM16 text-white transition-all">
        {menu.subMenu.map((sub: any) => {
          const Icon = ICON[sub.name];
          return (
            <div key={sub.name} className="mb-7 last:mb-0">
              <a
                className={`flex items-center opacity-70 hover:opacity-100 hover:TEXT-SHADOW-GLOW-1-d`}
                target="_blank"
                href={sub.url}
              >
                {Icon && <Icon className="mr-2" />}
                <span>{sub.name}</span>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutTabOpen, setIsAboutTabOpen] = useState(false);
  const [isCommunityTabOpen, setIsCommunityTabOpen] = useState(false);
  // const [isShowVideo, setIsShowVideo] = useState(false);
  const [firstVisitTime, setFirstVisitTime] = useState(Date.now());

  useEffect(() => {
    const _firstVisitTime = localStorage.getItem("first_visit_time");
    if (_firstVisitTime) {
      setFirstVisitTime(parseInt(_firstVisitTime));
    } else {
      localStorage.setItem("first_visit_time", `${Date.now()}`);
    }
  }, []);

  const isShowVideo = useMemo(() => {
    return Date.now() < firstVisitTime + 7 * 24 * 3600 * 1000;
    // return Date.now() < firstVisitTime + 30 * 1000;
  }, [firstVisitTime]);

  const openUrl = (url: string) => {
    setIsOpen(false);
    setIsAboutTabOpen(false);
    setIsCommunityTabOpen(false);
    window.open(url);
  };

  const navigate = (url: string) => {
    window.location.href = url;
    // @ts-ignore
    if (window.aap) {
      // @ts-ignore
      window.aap({ pixelKey: "3041578584258872492", pg: 14904 });
    }
  };

  return (
    <div className="bg-black min-h-screen relative flex flex-col overflow-hidden">
      <img
        src={BG}
        alt=""
        className="hidden md:block absolute w-screen h-full top-0 left-0 object-cover"
      />
      <img
        src={isShowVideo ? MobileBG : MobileMoonBG}
        alt=""
        className="md:hidden absolute w-screen h-full top-0 left-0 object-cover"
      />
      <div className=""></div>
      <div className="flex justify-center">
        <header className="relative py-5 pl-8 pr-4 flex justify-between basis-[1600px] items-center max-w-[1600px] z-20">
          <div className="">
            <img
              src={CrescentLogoSVG}
              alt=""
              className="hidden md:block w-[12.6875rem] z-20"
            />
            <img
              src={CrescentLogomarkSVG}
              alt=""
              className="block md:hidden w-[12.6875rem]"
            />
          </div>
          <div className="hidden md:block relative">
            <div className="inline-block relative pl-[1.5rem] pr-[2rem]">
              <DropdownMenu menu={ABOUT_MENU} />
            </div>
            <div className="inline-block relative px-[1rem]">
              <DropdownMenu menu={COMMUNITY_MENU} />
            </div>
            {/* <a
              className="inline sBOLD16 text-white opacity-50 hover:opacity-100 hover:TEXT-SHADOW-GLOW-1-d px-[1.125rem]"
              target={"_blank"}
              href="https://commonwealth.im/crescent"
            >
              Governance
            </a> */}
          </div>
          <div className="flex items-center">
            {/* <button
              className="text-grayCRE-500-d shadow-1 py-3 px-5 GRADIENT-YELLOW-100 BOLD16 rounded-[0.625rem] z-20 whitespace-nowrap mr-2"
              onClick={() => setIsShowVideo(!isShowVideo)}
            >
              (TEST)VIDEO
            </button> */}
            <button
              className="text-grayCRE-500-d shadow-glow-thin-d hover:shadow-glow-thin2-d transition-all py-3 px-5 GRADIENT-YELLOW-100 BOLD16 rounded-[0.625rem] z-20 whitespace-nowrap"
              onClick={() => navigate("https://app.crescent.network/")}
            >
              Launch App
            </button>
            <button
              className="block md:hidden text-white ml-5 z-20"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <IoMdClose className="w-6 h-6 drop-shadow-lg" />
              ) : (
                <GiHamburgerMenu className="w-6 h-6 drop-shadow-lg" />
              )}
            </button>
          </div>
        </header>
        {/* MOBILE_MENU */}
        {isOpen && (
          <div className="md:hidden absolute left-0 top-0 flex flex-col text-white min-w-[10rem] w-screen z-10">
            <div className="h-[5rem] bg-dark2"></div>
            <button
              className={`group px-5 bg-dark2 flex justify-between items-center py-5 sBOLD18 hover:TEXT-SHADOW-GLOW-1-d ${
                isAboutTabOpen && "TEXT-SHADOW-GLOW-1-d"
              }`}
              onClick={() => setIsAboutTabOpen(!isAboutTabOpen)}
            >
              <span className="opacity-70 group-hover:opacity-100">About</span>
              <div className="opacity-70 group-hover:opacity-100">
                {isAboutTabOpen ? <FiChevronUp /> : <FiChevronDown />}
              </div>
            </button>
            {isAboutTabOpen && (
              <div className="px-4 bg-[rgba(40,30,30,0.9)]">
                {ABOUT_MENU.subMenu.map((sub) => {
                  const Icon = ICON[sub.name];
                  return (
                    <button
                      className={`w-full opacity-70 flex items-center hover:opacity-100 hover:TEXT-SHADOW-GLOW-1-d py-4 MEDIUM14`}
                      onClick={() => openUrl(sub.url)}
                    >
                      <Icon className="mr-2" />
                      <span>{sub.name}</span>
                    </button>
                  );
                })}
              </div>
            )}
            <button
              className={`group px-5 bg-dark2 flex justify-between items-center py-5 sBOLD18 hover:TEXT-SHADOW-GLOW-1-d ${
                isCommunityTabOpen && "TEXT-SHADOW-GLOW-1-d"
              }`}
              onClick={() => setIsCommunityTabOpen(!isCommunityTabOpen)}
            >
              <span className="opacity-70 group-hover:opacity-100">
                Community
              </span>
              <div className="opacity-70 group-hover:opacity-100">
                {isCommunityTabOpen ? <FiChevronUp /> : <FiChevronDown />}
              </div>
            </button>
            {isCommunityTabOpen && (
              <div className="px-4 bg-[rgba(40,30,30,0.9)]">
                {COMMUNITY_MENU.subMenu.map((sub) => {
                  const Icon = ICON[sub.name];
                  return (
                    <button
                      className={`w-full opacity-70 flex items-center hover:opacity-100 hover:TEXT-SHADOW-GLOW-1-d py-4 MEDIUM14`}
                      onClick={() => openUrl(sub.url)}
                    >
                      <Icon className="mr-2" />
                      <span>{sub.name}</span>
                    </button>
                  );
                })}
              </div>
            )}
            {/* <button
              className="group px-5 bg-dark2 flex justify-between items-center py-5 sBOLD18 hover:TEXT-SHADOW-GLOW-1-d"
              onClick={() => openUrl("https://commonwealth.im/crescent")}
            >
              <span className="opacity-50 group-hover:opacity-100">
                Governance
              </span>
            </button> */}
          </div>
        )}
      </div>
      {/* {!isShowVideo && (
        <img
          src={BigMoonPNG}
          alt=""
          className="hidden md:block absolute w-[110rem] h-[110rem] -bottom-[30rem] right-0 object-cover"
        />
      )} */}
      {!isShowVideo && (
        <img
          src={Fullmoon}
          alt=""
          className="hidden md:block absolute w-[90rem] h-screen -bottom-0 -right-0 object-cover"
        />
      )}
      <div className="grow flex justify-center">
        <div className="relative flex flex-col basis-[1600px] max-w-[1600px]">
          <div className="flex items-center justify-center grow">
            {isShowVideo && (
              <YouTube
                className={`${
                  isMobile ? "h-[30vh]" : "h-[40vh]"
                } aspect-video mt-4 md:mt-[3.375rem]`}
                videoId="SeVauHTYzyE"
                opts={opts}
                onReady={() => {}}
              />
            )}
          </div>
          <div className="PC hidden md:flex flex-col justify-end px-8">
            <div className="mb-28 mt-8">
              <div className="text-[4rem] font-extrabold text-white leading-none TEXT-SHADOW-LANDING">
                Crescent Network
              </div>
              <div className="text-white opacity-80 text-[1.5rem] mt-3.5 TEXT-SHADOW-LANDING">
                Innovating DeFi investment through advancements in
                <br />
                inter-blockchain technology
              </div>
              <div className="mt-12 flex items-center">
                <button
                  className="text-grayCRE-500-d shadow-glow-thin-l hover:shadow-glow-thin2-l transition-all py-5 px-12 GRADIENT-YELLOW-100 BOLD16 rounded-[0.625rem] mr-12 flex items-center"
                  onClick={() => navigate("https://app.crescent.network/")}
                >
                  <span className="text-2xl text-grayCRE-500-d mr-4">
                    Launch App
                  </span>
                  <img src={CrescentLSVG} alt="" />
                </button>
                <div className="flex text-white items-start">
                  <a
                    className="mr-[2rem] md:mr-[1.875rem] hover:opacity-50 transition-opacity"
                    href="https://crescentnetwork.medium.com/"
                    target="_blank"
                  >
                    <BsMedium className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-1 drop-shadow-lg" />
                  </a>
                  <a
                    className="mr-[2rem] md:mr-[1.875rem] hover:opacity-50 transition-opacity"
                    href="https://t.me/crescentnetwork"
                    target="_blank"
                  >
                    <FaTelegramPlane className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-2 drop-shadow-lg" />
                  </a>
                  <a
                    className="mr-[2rem] md:mr-[1.875rem] hover:opacity-50 transition-opacity"
                    href="https://twitter.com/CrescentHub"
                    target="_blank"
                  >
                    <BsTwitter className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-2 drop-shadow-lg" />
                  </a>
                  <a
                    className="mr-[2rem] md:mr-[1.875rem] hover:opacity-50 transition-opacity"
                    href="https://discord.com/invite/ZUfrDnSX8G"
                    target="_blank"
                  >
                    <SiDiscord className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-2 drop-shadow-lg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="MOBILE flex md:hidden grow flex-col justify-end text-center">
            <div className="BOLD36 text-white leading-none TEXT-SHADOW-LANDING mt-8">
              Crescent Network
            </div>
            <div className="text-white opacity-80 text-[1.125rem] mt-3.5 TEXT-SHADOW-LANDING">
              Innovating DeFi investment through advancements in
              <br />
              inter-blockchain technology
            </div>
            <div className="flex justify-center mt-10">
              <button
                className="text-grayCRE-500-d shadow-glow-thin-l hover:shadow-glow-thin2-l transition-all py-[1.125rem] px-20 GRADIENT-YELLOW-100 BOLD16 rounded-[0.625rem] flex items-center"
                onClick={() => navigate("https://app.crescent.network/")}
              >
                <span className="BOLD20 text-grayCRE-500-d mr-4">
                  Launch App
                </span>
                <img src={CrescentLSVG} alt="" />
              </button>
            </div>
            <div className="flex text-white justify-center items-start mt-10 mb-[5.625rem]">
              <a
                className="mr-[2rem] md:mr-[1.875rem] hover:opacity-50 transition-opacity"
                href="https://crescentnetwork.medium.com/"
                target="_blank"
              >
                <BsMedium className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-1 drop-shadow-lg" />
              </a>
              <a
                className="mr-[2rem] md:mr-[1.875rem] hover:opacity-50 transition-opacity"
                href="https://t.me/crescentnetwork"
                target="_blank"
              >
                <FaTelegramPlane className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-2 drop-shadow-lg" />
              </a>
              <a
                className="mr-[2rem] md:mr-[1.875rem] hover:opacity-50 transition-opacity"
                href="https://twitter.com/CrescentHub"
                target="_blank"
              >
                <BsTwitter className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-2 drop-shadow-lg" />
              </a>
              <a
                className=" hover:opacity-50 transition-opacity"
                href="https://discord.com/invite/ZUfrDnSX8G"
                target="_blank"
              >
                <SiDiscord className="w-[2.5rem] h-[2.5rem] md:w-[3.125rem] md:h-[3.125rem] p-2 drop-shadow-lg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
