export const ABOUT_MENU = {
  name: "About",
  subMenu: [
    {
      name: "Blog",
      url: "https://crescentnetwork.medium.com/",
    },
    {
      name: "Docs",
      url: "https://docs.crescent.network/",
    },
    {
      name: "Github",
      url: "https://github.com/crescent-network/crescent",
    },
  ],
};

export const COMMUNITY_MENU = {
  name: "Community",
  subMenu: [
    {
      name: "Discord",
      url: "https://discord.com/invite/ZUfrDnSX8G",
    },
    {
      name: "Telegram",
      url: "https://t.me/crescentnetwork",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/CrescentHub",
    },
    {
      name: "Youtube",
      url: "https://www.youtube.com/channel/UCdKMjp1rbk7VyvEK_Oe4Cfg",
    },
  ],
};
